body {
  margin: 0;
  font-family:
    'Roboto',
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.fade-appear,
.fade-enter {
  opacity: 0;
  transform: translateY(40px);
}

.fade-appear-active,
.fade-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition:
    opacity 300ms,
    transform 300ms;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity 300ms;
}

/* Profile 컴포넌트 특정 스타일 */
.profile {
  position: absolute;
  width: 100%;
  background: white;
}

:root {
  --rsbs-backdrop-bg: rgba(0, 0, 0, 0.1);
  --rsbs-bg: rgba(246, 246, 246, 1);
  --rsbs-handle-bg: rgba(216, 216, 216, 1);
  --rsbs-max-w: auto;
  --rsbs-ml: env(safe-area-inset-left);
  --rsbs-mr: env(safe-area-inset-right);
  --rsbs-overlay-rounded: 16px;
}

@media (min-width: 600px) {
  :root {
    --rsbs-max-w: 600px;
    --rsbs-ml: auto;
    --rsbs-mr: auto;
  }
}

.sub {
  text-align: center;
  width: 100%;
  padding-top: 20px;
  color: rgba(42, 42, 42, 0.5);
  font-weight: 500;
  font-size: 16px;
}
